
import Vue from "vue";
import SessionMenu from "@/components/Session/SessionMenu.vue";
import MentorcastData from "@/components/Mentorcast/MentorcastData.vue";
import RecommendedMentors from "@/components/Mentor/RecommendedMentors.vue";
import { mapGetters } from "vuex";
import MentorcastsLoading from "@/components/Mentorcast/MentorcastsLoading.vue";

export default Vue.extend({
  name: "MentorcastsPage",
  components: {
    // Pagination,
    MentorcastData,
    SessionMenu,
    RecommendedMentors,
    MentorcastsLoading
  },
  data() {
    return {
      loading: true,
      tabs: [
        (this as any).$t("mentorcasts.allTab"),
        (this as any).$t("mentorcasts.completedTab"),
        (this as any).$t("mentorcasts.upcomingTab")
      ],
      data: [] as any,
      page: 1,
      filter: {},
      currentTab: (this as any).$t("mentorcasts.allTab"),
      last_page: 0,
      isIHave: true,
      null_data: false,
      load_more: false
    };
  },
  computed: {
    ...mapGetters({
      mentorcasts: "mentorcast/getMentorcasts",
      profile: "profile/getProfile"
    })
  },
  mounted() {
    if (this.profile.type == "mentor") {
      this.tabs.push((this as any).$t("mentorcasts.participatingTab"));
    }
    this.getMentorcasts(this.page);
  },
  methods: {
    getMentorcasts(page: any) {
      this.loading = true;
      const data = {
        [this.profile.type === "mentor" ? "is_mine" : "is_mentee"]: true,
        ...this.filter
      };
      this.$store
        .dispatch("mentorcast/GET_ALL_MENTORCAST", {
          data: {
            ...data,
            type: this.currentTab,
            page
          }
        })
        .then(data => {
          this.page = page;
          this.load_more = page < data.last_page;
          this.data.push(...data.data);
          this.loading = false;
        });
    },
    onTab(tab: any) {
      this.data = [];
      this.page = 1;
      this.last_page = 0;
      this.currentTab = tab;
      this.getMentorcasts(this.page);
    },
    onLoadMore() {
      this.page++;
      this.getMentorcasts(this.page);
    },
    onSearch(filter: any) {
      this.isIHave = false;
      this.data = [];
      this.filter = filter;
      this.getMentorcasts(this.page);
    },
    generateIcs(id: string) {
      this.$store.dispatch("mentorcast/GENERATE_ICS", id).then(data => {
        this.downloadURI(data.ics, "Mentorcast");
      });
    },
    downloadURI(uri: any, name: any) {
      const link = document.createElement("a");
      link.setAttribute("download", name);
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
});
