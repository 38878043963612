<template>
  <div>
    <div
      v-for="(_, i) in 3"
      :key="i"
      class="bg-white position-relative border-radius-4 px-3 py-5"
    >
      <b-row no-gutters>
        <b-col cols="auto">
          <PuSkeleton height="30px" width="30px" circle class="d-block" />
        </b-col>
        <b-col class="text-left text-dark pr-6 pt-1 ml-4">
          <PuSkeleton height="30px" width="100%" class="d-block w-100" />
          <PuSkeleton height="18px" width="100px" class="d-block" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "RecommendedMentorsLoading"
});
</script>
