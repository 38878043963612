
import Vue from "vue";
import { mapGetters } from "vuex";
import RecommendedMentorsLoading from "@/components/Mentor/RecommendedMentorsLoading.vue";

export default Vue.extend({
  name: "RecommendedMentors",
  components: {
    RecommendedMentorsLoading
  },
  data() {
    return {
      page: 1,
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      recommendation: "recommended/getRecommendation"
    })
  },
  created(): void {
    this.getRecommendation();
  },
  methods: {
    getRecommendation() {
      this.loading = true;
      this.$store
        .dispatch("recommended/GET_RECOMMENDATION", {
          type: "mentor",
          page: this.page
        })
        .then(() => {
          this.loading = false;
        });
    },
    moreRecommendations() {
      if (this.page <= this.recommendation.last_page - 1) {
        this.page++;
        this.getRecommendation();
      }
    },
    getCategoryType(mentor: any, key: string): any {
      if (mentor.category_types[key]) {
        return mentor.category_types[key][0];
      }
    }
  }
});
